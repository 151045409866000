import { Controller } from 'stimulus'
import { Popover } from 'bootstrap'

export default class extends Controller {
  static values = {
    title: String,
    content: String,
    trigger: String
  }

  connect () {
    this.popover = new Popover(this.element, {
      trigger: this.trigger,
      title: this.title,
      content: this.content
    })
  }

  disconnect () {
    this.popover.dispose()
  }

  get title () {
    return this.titleValue || this.element.title
  }

  get content () {
    return this.contentValue
  }

  get trigger () {
    return this.triggerValue
  }
}
