import { Controller } from 'stimulus'
import { Alert } from 'bootstrap'

export default class extends Controller {
  connect () {
    setTimeout(() => this.alert?.close(), 2500)
  }

  get alert () {
    return Alert.getOrCreateInstance(this.element)
  }
}
