import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'item', 'collection', 'blank']

  add (event) {
    const clone = this.templateTarget.content.firstElementChild.cloneNode(true)
    const index = new Date().getTime()

    clone.querySelectorAll('input').forEach(input => {
      input.name = input.name.replace(/(.+_attributes\]\[)([^\]]+)(\].+)/,
        (_, prefix, _index, affix) => `${prefix}${index}${affix}`
      )
    })

    this.collectionTarget.insertAdjacentElement('beforeend', clone)
    this.toggleEmptyStateTarget()
  }

  remove (event) {
    const item = this.itemTargets.find(node => node.contains(event.currentTarget))
    const idInput = item.querySelector('input[name$="[id]"]')

    if (idInput) {
      item.hidden = true
    } else {
      item.remove()
    }

    this.toggleEmptyStateTarget()
  }

  toggleEmptyStateTarget () {
    if (this.hasBlankTarget) {
      this.blankTarget.hidden = !this.isEmpty
    }
  }

  get items () {
    return this.collectionTarget.querySelectorAll(':not([hidden])')
  }

  get isEmpty () {
    return this.items.length === 0
  }
}
