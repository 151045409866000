import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['item']
  static classes = ['active']

  toggle (event) {
    this.itemTargets.forEach(element => {
      element.classList.toggle(this.activeClass, event.currentTarget === element)
    })
  }
}
