import { Controller } from 'stimulus'
import { useTargetMutation } from 'stimulus-use'

export default class extends Controller {
  static values = {
    capacity: Number,
    available: Number
  }

  static targets = ['reservation', 'available', 'capacity']

  connect () {
    useTargetMutation(this)
    this.availableValue = this.capacityValue - this.reservationTargets.length
  }

  availableValueChanged (value) {
    this.availableTarget.innerText = this.availableValue
  }

  reservationTargetAdded (element) {
    this.availableValue -= 1
  }

  reservationTargetRemoved (element) {
    this.availableValue += 1
  }
}
