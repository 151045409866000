/* eslint-disable no-unused-vars */

import * as Turbo from '@hotwired/turbo'
import * as AnimateOnScroll from 'aos'
import * as TurboCookieFirst from '../frontend/turbo_cookie_first'

import 'controllers'

AnimateOnScroll.init()
TurboCookieFirst.start()
