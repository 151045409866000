import { Controller } from 'stimulus'
import { useClickOutside, useDebounce } from 'stimulus-use'

export default class extends Controller {
  static values = { url: String }
  static targets = ['input', 'results']
  static debounces = ['handleInput']

  connect () {
    useClickOutside(this, { element: this.resultTarget })
    useDebounce(this)

    this.hide()
    this.inputTarget.addEventListener('input', this.handleInput)
  }

  disconnect () {
    this.inputTarget.addEventListener('input', this.handleInput)
  }

  handleInput = (event) => {
    if (this.term.length === 0) {
      return this.hide()
    }

    const url = new URL(this.urlValue)
    url.searchParams.append('q', this.term)

    this.show()
    this.resultsTarget.src = url
  }

  clickOutside = (event) => {
    this.hide()
  }

  hide = () => {
    this.resultsTarget.hidden = true
  }

  show = () => {
    this.resultsTarget.hidden = false
  }

  get term () {
    return this.inputTarget.value
  }
}
