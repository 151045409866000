import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    document.addEventListener('input', this.handleInput)
  }

  disconnect () {
    document.removeEventListener('input', this.handleInput)
  }

  handleInput = (event) => {
    event.target.classList.toggle('is-invalid', false)
  }
}
