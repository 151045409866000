export async function start () {
  const cf = await ready()

  document.addEventListener('turbo:render', cf.renderEmbeds)
}

function ready () {
  return new Promise(resolve => {
    if (window.CookieFirst) {
      resolve(window.CookieFirst)
    } else {
      window.addEventListener('cf_init', () => resolve(window.CookieFirst))
    }
  })
}
