import { Controller } from 'stimulus'
import { useDebounce } from 'stimulus-use'
import { enter, leave } from '../utils/el-transition'

export default class extends Controller {
  static targets = ['term', 'item', 'noResults']
  static debounces = ['apply']

  connect () {
    useDebounce(this)
    this.apply()
  }

  apply (event) {
    const regex = new RegExp(this.term, 'i')
    let enterNth = 0

    for (const element of this.itemTargets) {
      const visible = regex.test(element.innerText)
      if (visible) {
        element.style.setProperty('--nth', enterNth++)
        enter(element)
      } else {
        leave(element)
      }
    }

    const any = enterNth > 0
    this.noResultsTarget.classList.toggle('d-none', any)
  }

  get term () {
    return this.termTarget.value
  }

  get visibleItems () {
    return this.itemTargets
      .filter(function isVisible (element) {
        return !element.classList.contains('d-none')
      })
  }
}
