import { Controller } from 'stimulus'
import { useDebounce } from 'stimulus-use'

const { requestAnimationFrame } = window

export default class extends Controller {
  static targets = ['item', 'head', 'categoryFilter', 'termFilter']
  static debounces = ['update']

  connect () {
    useDebounce(this)

    this.element.addEventListener('show.bs.dropdown', this.focus)
  }

  disconnect () {
    this.element.removeEventListener('show.bs.dropdown', this.focus)
  }

  focus = event => {
    requestAnimationFrame(() => {
      this.termFilterTarget.focus()
    })
  }

  update () {
    const regex = new RegExp(this.termFilterTarget.value, 'i')

    this.itemTargets.forEach(item => {
      item.classList.toggle('d-none', !(
        this.categoryIds.includes(item.dataset.categoryId) &&
        regex.test(item.innerText)
      ))
    })
    this.headTargets.forEach(item => {
      item.classList.toggle('d-none',
        !this.categoryIds.includes(item.dataset.categoryId)
      )
    })
  }

  checkAllCategories (event) {
    event.preventDefault()
    this.categoryFilterTargets.forEach(function (element) {
      element.checked = true
    })
    this.update()
  }

  get categoryIds () {
    return this.categoryFilterTargets
      .filter(element => element.checked)
      .map(element => element.value)
  }
}
