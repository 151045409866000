import { Controller } from 'stimulus'
import html from 'facon'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = {
    message: String,
    yes: String,
    no: String
  }

  confirmed = false

  connect () {
    this.element.addEventListener('click', this.handleClick)
  }

  disconnect () {
    this.element.removeEventListener('click', this.handleClick)
  }

  handleClick = (event) => {
    if (this.confirmed) {
      this.confirmed = false
      return
    }

    event.preventDefault()
    confirm({
      messageText: this.message,
      yesText: this.yes,
      noText: this.no
    }).then((confirmed) => {
      this.confirmed = confirmed
      if (confirmed) {
        this.element.click()
      }
    })
  }

  get message () {
    return this.messageValue || 'Are you sure?'
  }

  get yes () {
    return this.yesValue || 'Yes'
  }

  get no () {
    return this.noValue || 'No'
  }
}

function confirm ({ messageText, noText, yesText }) {
  return new Promise(function (resolve, reject) {
    const node = html`
      <div ref="modal" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              ${messageText}
            </div>
            <div class="modal-footer">
              <button value="no" type="button" class="btn btn-secondary">${noText}</button>
              <button value="yes" type="button" class="btn btn-danger">${yesText}</button>
            </div>
          </div>
        </div>
      </div>
    `
    const el = node.collect()
    const modal = new Modal(el.modal)

    el.modal.addEventListener('hidden.bs.modal', handleHidden)
    el.modal.addEventListener('click', handleClick)

    document.body.appendChild(node)
    modal.show()

    function handleClick (event) {
      if (!event.target.matches('button')) {
        return
      }

      const confirmed = event.target.matches('[value="yes"]')
      modal.hide()
      resolve(confirmed)
    }

    function handleHidden () {
      modal.dispose()
      this.remove()
      resolve(false)
    }
  })
}
